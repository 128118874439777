import React, { Component} from 'react';
import { Grid, Cell} from 'react-mdl';
import Particles from 'react-particles-js';



class landingPage extends Component {
    render(){
        return(

            
            <div className= "landing-grid">
                
                <Grid >
                    <Cell col={12}>

                    <img src="https://raw.githubusercontent.com/Fiasalhotaky/Portfolio/master/public/logo1.PNG" alt="" style={{height: '100px', paddingTop: '0em'}} />

                    <div >
                        
                        <h1 style={{fontSize: '60px', color: '#3E5151', padding: '0.1'}}>Fiasal Hotaky</h1>

                        <hr style={{borderTop: '5px groove #3E5151', paddinTop: '0.01em'}}/>

                        <p style={{width: '100%', margin: 'auto', fontSize: '35px', fontWeight: 'bold', minWidth: 'auto', color: '#3E5151', paddingBottom: '0.5em'}}>Full Stack Developer</p>

                    <div className= "media">

                        <a href="mailto:fiasalhotaky@gmail.com"
                            style={{fontSize: '15px', fontFamily: 'Mukta', color: '#3E5151' }}>
                            Email
                            <i className= "fa fa-envelope" aria-hidden= "true" /> 
                        </a>

                        <a href="https://github.com/Fiasalhotaky" rel="noopenner noreferrer" targets="_blank"
                            style={{fontSize: '15px', fontFamily: 'Mukta', color: '#3E5151' }}>
                                Github
                            <i className="fa fa-github-square" aria-hidden="true" />
                        </a>

                        <a href="https://www.linkedin.com/in/Fiasalhotaky/" rel="noopenner noreferrer" targets="_blank"
                            style={{fontSize: '15px', fontFamily: 'Mukta', color: '#3E5151' }}>
                                LinkedIn
                            <i className="fa fa-linkedin-square" aria-hidden="true" />
                        </a>

                        <a href="https://resume.creddle.io/resume/2wp8f64e89w" rel="noopenner noreferrer" targets="_blank"
                            style={{fontSize: '15px', fontFamily: 'Mukta', color: '#3E5151' }}>
                                Resume
                            <i className="fa fa-file" aria-hidden="true" />
                        </a>
                        

                    </div>
                    </div>
                    <Particles className= "particles-land"
    params={{
	    "particles": {
	        "number": {
	            "value": 90,
	            "density": {
	                "enable": true,
	                "value_area": 900
	            }
            },
            
            color: {
                value: "#3E5151",
              },
	        "line_linked": {
	            "enable": true,
                "opacity": 2,
                'color': '#3E5151',
                hoverEffect: true
                 
	        },
	        "move": {
	            "direction": "top",
	            "speed": 1.2,
	        },
	        "size": {
	            "value": 1
	        },
	        "opacity": {
	            "anim": {
	                "enable": true,
	                "speed": 1,
	                "opacity_min": 2
	            }
	        }
	    },
	    "interactivity": {
	        "events": {
	            "onclick": {
	                "enable": true,
	                
	            }
            },
            "onHover": {
                "enable": true,
                "mode": "repulse",
              },
            "repulse":{
                "distance": 500,
                "duration": 0.5
            },
            
	        "modes": {
                "bubble": {
                  "distance": 400,
                  "duration": 2,
                  "opacity": 0.8,
                  "size": 40,
                },
                "push": {
                  "quantity": 4,
                },
              },
            
	    },
	    "retina_detect": true
	}} />

                    </Cell>

            </Grid>

            </div>
        )
    }
}

export default landingPage;